<button
  pRipple
  [pTooltip]="tooltip"
  [tooltipPosition]="tooltipPosition"
  [disabled]="disabled || loading"
  [type]="type"
  [ngClass]="{
    'btn-primary': variant === 'primary',
    'btn-secondary': variant === 'secondary',
    'btn-danger': variant === 'danger',
    'btn-outline-primary': variant === 'outline-primary',
    'btn-outline-secondary': variant === 'outline-secondary',
    'btn-outline-danger': variant === 'outline-danger',
    'btn-ghost-primary': variant === 'ghost-primary',
    'btn-ghost-secondary': variant === 'ghost-secondary',

    'btn-xs': size === 'xs',
    'btn-sm': size === 'sm',
    'btn-md': size === 'md',
    'btn-lg': size === 'lg',
    'btn-xl': size === 'xl'
  }"
  [class]="'btn flex items-center gap-1 ' + styleClass"
  (click)="onClick.emit($event)">
  <span *ngIf="label" class="order-2 font-medium">{{ label }}</span>
  <i *ngIf="!!icon" [class]="icon" class="text-4" [ngClass]="iconPosition === 'right' ? 'order-3' : 'order-1'"></i>
  <i *ngIf="loading" class="pi pi-spin pi-spinner order-4 text-4"></i>
  <i *ngIf="isDropdown" class="icon-wl-chevron-down text-4 order-2"></i>
</button>
